import { memo, Suspense, useCallback } from "react";
import MarkdownEditor from "~/components/MarkdownEditor";
import { useAppStore } from "~/store";
import { AuthorPerm } from "~/utils/hive";
import { prepareFakeThread } from "~/utils/thread";
import { postThreadMain, postThreadReply } from "~/utils/transactions";

export enum MarkdownEditorTypes {
  Post,
  Thread,
  Comment
}

interface ThreadsEditorProps {
  type?: MarkdownEditorTypes;
  mainThreadEditor?: boolean;
  encryption?: boolean;
  setEncryption?: React.Dispatch<React.SetStateAction<boolean>>;
  pushToStart?: Function;
  initialContent?: string;
  parentAuthorPerm?: AuthorPerm;
  className?: string;
}

const ThreadsEditor = memo(
  ({
    type,
    mainThreadEditor,
    encryption,
    setEncryption,
    pushToStart,
    initialContent,
    parentAuthorPerm,
    className
  }: ThreadsEditorProps) => {
    const activeAccount = useAppStore(store => store.account.activeAccount);
    const pushToStartArray = useAppStore(store => store.threads.pushStartArray);

    const handlePost = useCallback(
      async (body: string, pollOptions?: object, dimensions?: any) => {
        if (!activeAccount) return;

        let operation;
        if (parentAuthorPerm) {
          operation = await postThreadReply(activeAccount!.name, body, parentAuthorPerm);
        } else {
          operation = await postThreadMain(
            activeAccount!.name,
            body,
            pollOptions,
            dimensions,
            encryption,
            activeAccount
          );
        }

        if (typeof pushToStart === "function") {
          pushToStart(
            prepareFakeThread({
              operation,
              activeAccount
            })
          );
        } else {
          pushToStartArray(
            prepareFakeThread({
              operation,
              activeAccount
            })
          );
        }
      },
      [pushToStartArray, activeAccount, encryption]
    );
    if (!activeAccount) return null;

    return (
      <div className="flex flex-col flex-1">
        <Suspense fallback={<MarkdownEditorPlaceholder />}>
          <MarkdownEditor
            type={type || MarkdownEditorTypes.Thread}
            mainThreadEditor={mainThreadEditor}
            handlePost={handlePost}
            encryption={encryption}
            initialContent={initialContent || ""}
            setEncryption={setEncryption}
            className={className}
          />
        </Suspense>
      </div>
    );
  }
);

ThreadsEditor.displayName = "ThreadsEditor";
export default ThreadsEditor;

function MarkdownEditorPlaceholder() {
  return (
    <div className="flex flex-col w-full h-[133px] border-b border-pri dark:border-pri-d animate-pulse">
      <div className="flex items-center w-full h-20 gap-x-4 p-4">
        <span className="flex shrink-0 w-12 h-12 rounded-full bg-gray-200 dark:bg-zinc-800" />
        <span className="flex w-36 h-6 rounded bg-gray-200 dark:bg-zinc-800" />
      </div>

      <div className="flex items-center justify-between w-full h-[52px] p-2 gap-x-3">
        <div className="flex flex-1 items-center shrink-0">
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(key => (
            <div key={key} className="flex items-center justify-center shrink-0 w-9 h-9 overflow-hidden">
              <span key={key} className="flex w-7 h-7 bg-gray-200 dark:bg-zinc-800 rounded-full" />
            </div>
          ))}
        </div>

        <span className="flex w-[88px] h-[35px] rounded-full bg-gray-200 dark:bg-zinc-800" />
      </div>
    </div>
  );
}
